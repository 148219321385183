import { Link as ScrollLink } from 'react-scroll';
import { useLocation, Outlet } from 'react-router-dom';
// material
import { Box, Link, Container, Typography } from '@material-ui/core';
// components
import Logo from '../../components/Logo';
import MainNavbar from './MainNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

export default function MainLayout() {
  const { pathname } = useLocation();
  const isHome = pathname === '/';
  const isSiteMap = pathname === '/site-map';
  const isEnquiry = pathname === '/skyniche-enquiry-details';
  const isBrochur = pathname === '/skyniche-indiamobilecongress-brochure';
  const isBrochurQR = pathname === '/brochure-qr-scanner';

  return (
    <>
      {!isEnquiry &&!isBrochur&& <MainNavbar />} 
      
      <div>
        <Outlet />
      </div>

      {!isEnquiry && !isSiteMap && !isBrochur &&!isBrochurQR &&(
        <MainFooter /> 
      )}
    </>
  );
}
